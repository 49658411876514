.mainbody {
  margin-top: 50px;
  width: 30%;
  text-align: center;
  margin: 0 auto;
}
.main_theme1_srp{
  mask: url(/images/back-1.jpg) no-repeat center/contain;
  -webkit-mask: url(/images/back-1.jpg) no-repeat center/contain;
}
.login_wrap {
  margin-top: 10px;
  width: 400px;
  margin: 0 auto;
}
.login_wrap p{
  margin-bottom: 20px;
  font-family: var(--font_family);
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #383838;
}
.login_wrap input{
  width: 100%;
  /* margin-bottom: 15px; */
  margin-bottom: 0px;
  border-radius: 30px;
}
.btn_login {
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  text-align: center;
  color: #fff;
  height: 52px;
  margin: 30px 0;
  padding: 13px;
  width: 100%;
  background-color: rgb(200, 10, 10);
  border-radius: 30px;
}

.below_text{
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.signupdiv{
  margin-bottom: 20px;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #000;
}
.signupdiv span{
  color: #6684f2;
}
.centerdiv input{
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #959595;
}
.leftdiv input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  margin-bottom: 0px;
  margin-right: 20px;
}
.leftdiv{
  width: 50%;
  text-align: left;
}
.leftdiv span{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.rightdiv{
  width: 50%;
  text-align: right;
}
.rightdiv span{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #6684f2;
}
.forgotpassection{
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.btnor{
  position: relative;
  border-bottom: 1px solid #606060;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.btnor span{
  position: absolute;
  top: -13px;
  background: #ffffff;
  padding: 0px 10px 0 10px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #959595;
}
.socialicons{
  display: flex;
  margin: 30px;
  justify-content: space-around;
}
.socialicons img{
  width: 50px;
  height: 50px;
}
.below_text span{
  color:#6684f2;
}
.signupdiv span{
  color:#6684f2;
}
.signupmain p{
  font-family: var(--font_family);
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #383838;
  margin-bottom: 20px;
}
.signupmain {
  margin-top: 10px;
  width: 400px;
  margin: auto;
}
.signupaccept{
  display: flex;
  margin: 10px 0;
}
.signupaccept span{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.signupaccept input[type=checkbox] {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  margin-bottom: 0;
  margin-right: 20px;
}
.signupinput input{
  font-family: var(--font_family);
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #959595;
    width: 100%;
    border-radius: 30px;
}
.bottomsignup{
  font-family: var(--font_family);
  margin-bottom: 20px;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #000;
}
.bottomsignup span{
  color: #6684f2;
}

/* theme1 css */

.login_container{
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: url(/theme2/logn_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-color: var(--primary_color); */
}

.login_card_container{
width: 90%;
height: 100vh;
margin: auto;
display: flex;
align-items: center;
justify-content: center;
}

.login_card{
  /* width: 50%; */
  background-color: transparent;
  /* margin-top: 10%; */
}

.login_form_container{
  padding: 20px;
  margin-bottom: 20px;
}

.btn_login {
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  text-align: center;
  color: #fff;
  height: 52px;
  margin: 30px 0;
  padding: 13px;
  width: 100%;
  /* background-color: var(--buttons_color); */
   background-color: rgb(194, 45, 45);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.fill_input:focus {
  box-shadow: none;
  outline: none;
  border-color: #c3c3c3;
}

.fill_input {
  width: 100%;
  height: 50px;
  margin: 20px 0 0;
  padding: 15px;
  border: solid 1px #c3c3c3;
  background-color: #fff;
  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--font_color);
}
.fill_input::placeholder {
  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--font_color);
}
.fill_input:disabled {
  background: #f0eeee;
  color: var(--font_color);
}

.img_div{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.img_div img {
  width: 190px;
  height: auto;
}

.business_logo {
width: 50%;
}
.business_logo img {
width: 300px;
height: auto;
margin: auto;
}
.request_info{
font-family: var(--font_family);
color: #fff;
}
.request_detail{
font-size: 20px;
font-weight: normal;
line-height: normal;
letter-spacing: 0.5px;
color: #fff;
margin-bottom: 15px;
}
.request_title{
font-size: 16px;
font-weight: normal;
line-height: normal;
letter-spacing: 0.45px;
color: #fff;
margin-bottom: 10px;
}
.request_info_point{
font-size: 15px;
font-weight: normal;
line-height: normal;
letter-spacing: 0.38px;
color: #fff;
margin-bottom: 10px;
}
.request_info_point li {
list-style-type: none;
margin-bottom: 2px;
}
.request_info_point li:before {
content: "";
background: #fff;
font-weight: bold;
width: 10px;
height: 10px;
border: 1px solid #fff;
border-radius: 50px;
display: inline-block;
text-align: center;
padding: 3px;
margin: 0px 10px;
}

.button_div{
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  margin: 0 40px;
}
.login_btn{
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  /* height: 41px; */
  border: 1px solid var(--secondary_color);
  background-color: var(--secondary_color);
  /* display: flex; */
  align-items: center;
  padding: 3px 8px;
  white-space: nowrap;
  margin-right: 18px;

}

.forgot_password {
  margin: 20px 0 0;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--secondary_color);
  cursor: pointer;
}

.country_drp{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.country_drp>div:first-child{
width: 35%;
margin-top: 20px;
}
.country_drp>div:last-child{
  width: 62%;
}
.country_drop{
  width: 100% !important;
  margin-right: 8px;
}
.vt_country_select_container{
  display: flex;
  align-items: center;
  padding: 0 25px 0 10px;
  box-sizing: border-box;
  border: 1px solid #bdbdbd;
  border-radius: 0px;
  height: 51px;
}
.login_btn:last-child {
  margin-right: 0;
}

.modalb_wrap{
margin-top: 15px;
}
.modb_icon{
margin-bottom: 10px;
}
.modb_icon img{
width: 92px;
margin: auto;
}
.modb_title{
font-family: var(--font_family);
font-size: 14px;
font-weight: normal;
line-height: 1.5;
letter-spacing: 0.35px;
text-align: center;
color: var(--font_color);
}

.email_error_msg {
margin: 5px 0 0px;

font-family: var(--font_family);

font-size: 12px;

font-weight: normal;

font-stretch: normal;

font-style: normal;

line-height: normal;

letter-spacing: 0.3px;

text-align: left;

color: red;
}

.email_id:focus {
box-shadow: none;
outline: none;
border-color: #c3c3c3;
}

.email_id {
width: 100%;
height: 41px;
margin: 30px 0 0;
padding: 14px;
border: solid 1px #c3c3c3;
background-color: #fff;
font-family: var(--font_family);
font-size: 15px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.38px;
text-align: left;
color: var(--font_color);
}

.password_login_card{
width: 650px;
background-color: #fff;
/* margin-top: 10%; */
}
.login_by_mail_id_text{
font-family: var(--font_family);
font-size: 22px;
font-weight: 600;
line-height: normal;
letter-spacing: 0.55px;
color: var(--font_color);
}

.password_update_text{
font-family: var(--font_family);
font-size: 22px;
font-weight: 600;
line-height: normal;
letter-spacing: 0.55px;
color: var(--font_color);
text-align: center;
}

.password_success_text{
margin: 13px 73px 51px 74px;
font-family: var(--font_family);
font-size: 14px;
font-weight: normal;
line-height: 1.43;
letter-spacing: 0.35px;
color: #45aa01;
text-align: center;
}

.password_success_btn{
width: 51%;
margin-left: 152px;
}

.btn_password {
font-size: 20px;
font-weight: normal;
line-height: normal;
letter-spacing: 0.63px;
text-align: center;
color: #fff;
height: 52px;
margin: 30px 0;
padding: 13px;
width: 100%;
background-color: var(--buttons_color);
display: flex;
align-items: center;
justify-content: center;
border-radius: 5px;
}

.pswd_text{
font-family: var(--font_family);
font-size: 14px;
font-weight: normal;
line-height: 1.43;
letter-spacing: 0.35px;
color: var(--font_color);
margin-top: 6px;
}

.invalidmodal_wrap .brand_logo img{
width: 190px;
margin: 0 auto;
}
.inv_heading{
font-family: var(--font_family);
font-size: 22px;
font-weight: 600;
line-height: normal;
letter-spacing: 0.55px;
color: var(--font_color);
margin-bottom: 6px;
}
.inv_title{
font-family: var(--font_family);
font-size: 14px;
font-weight: normal;
line-height: 1.43;
letter-spacing: 0.35px;
color: var(--font_color);
}
.button_wrap .reset_btn{
height: 52px;
padding: 10px 45px 10px;
border-radius: 5px;
background-color: var(--buttons_color);
font-size: 20px;
font-weight: normal;
line-height: 1.1;
letter-spacing: 0.5px;
text-align: left;
color: #fff;
}
.button_wrap .cancel_btn{
height: 52px;
padding: 10px 45px 10px;
border-radius: 5px;
border: solid 1px var(--secondary_color);
background-color: #fff;
font-family: var(--font_family);
font-size: 20px;
font-weight: normal;
line-height: 1.1;
letter-spacing: 0.5px;
color: var(--secondary_color);
}
.invalidmodal_wrap {
width: 400px;
margin: auto;
}

.button_wrap {
margin-top: 20px;
display: flex;
justify-content: space-between;
}

.brand_logo {
margin-bottom: 15px;
}
.signup_card_container{
  width: 50%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_loader {
border: 5px solid #f3f3f3;
border-radius: 50%;
border-top: 5px solid #3498db;
width: 38px;
height: 38px;
position: relative;
-webkit-animation: spin 4s linear infinite;
animation: spin 4s linear infinite;
top: -76px;
left: 45%;
right: 50%;
}