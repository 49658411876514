.timeoutotp{
    display: block;
    color: var(--secondary_color);
    text-align: center;
    font-size: 16px;
}
.timeoutotpresent{
    cursor: pointer;
    display: block;
    color: var(--secondary_color);
    text-align: center;
    font-size: 16px;
}
.mainotptext{
    font-size: 14px;
    color: var(--font_color);
    text-align: center;

}
    .otpboxesmain{
    display: flex;
    justify-content: space-between;
    padding:15px 80px ;
}
.otpboxesmain input{
    width: 32px;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #d1d1d1!important;
    padding: 2px;
    display: flex;
    align-items: center;
    font-size: 14px;
    text-align: center;
}
.otpboxesmain input:focus{
    outline: 0;
    box-shadow: none;
    border: none;
}
.email_error_msg{
    text-align: center!important;
}
.email_error_msgotp{
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .3px;
    color: red;
    text-align: center;
}

@media only screen and (max-width: 767px) and (min-width: 320px){

    .otpboxesmain{
        padding: 10px;
    }
    .email_error_msg{
        text-align: center;
    }
}