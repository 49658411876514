.country_div :global(.theme4_country_drop__R91dD){
  width: 100%;
  margin-left: 0;
  height: 48px;
}
.country_div :global(.theme4_vt_country_select_main_container_new__jiiDM .theme4_vt_country_select_container__7uhbU){
    width: 100%;
    height: 42px !important;
    margin: 18px 0 0;
    background-color: #fff;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--font_color) !important;
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #e0e0e0;
    padding: 0px 6px;
    cursor: pointer;
    border-radius: 0;
}
.country_div :global(span.theme4_drop_arrow__BIBuY img){
  position: absolute;
  width: 14px !important;
  height: auto !important;
  right: 7px;
  top: 20px;
}
.country_div :global(.theme4_vt_country_select_main_container_new__jiiDM .theme4_vt_country_select_container__7uhbU input.theme4_vt_inspira_country_main__XhUk5){
  font-size: 15px;
  font-weight: 500;
}
.isd_main{
  width: 50%;
}

.login_container{
    width: 100%;
    height: 100vh;
    padding-top: 20px;
    /* padding-bottom: 20px; */
    /* background: url(/theme4/Manama_back2.jpg)center/cover; */
    background-color: #c3c3c3;
}

.login_card_container{
    /* width: 600px; */
    /* height: 600px; */
    width: 100%;
    /* max-width: 70%; */
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 60px !important; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pass_icon img {
  position: absolute;
  right: 10px !important;
  top: 50%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  width: 25px;
  height: 20px;
  left: auto !important;
}

.login_card{
    max-width: 50%;
    background-color: #fff;
    /* margin-top: 10%; */
    border-radius: 30px 30px;
    height: auto;
    z-index: 20;
}

.login_form_container{
    padding: 20px 60px;
    /* margin-bottom: 20px; */
    width: 100%;    
    max-width: 550px;
    max-height: 540px;
    overflow-y: auto;
    /* margin-top: 30px; */
}

.login_form_container::-webkit-scrollbar {
  width: 6px;
}

.login_form_container::-webkit-scrollbar-track {
  background: #e0e0e0; 
  border-radius: 4px;   
}

.login_form_container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 4px; 
}


.btn_wrap_new .btn_login{
  height: 40px;
  min-width: 120px;
}

.btn_login {
  min-width: 100px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.63px;
    text-align: center;
    color: #fff;
    height: 45px;
    margin: 20px 0;
    padding: 13px;
    /* width: 100%; */
    background-color:var(--secondary_color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    margin: 20px auto;
  }
  .fget_pass{
    text-align: center;
  }
  .cget_us{
    text-align: center;
    margin-top: 15px;
  }
  .fill_input:focus {
    box-shadow: none;
    outline: none;
    border-color: #c3c3c3;
  }
  
  .fill_input {
    width: 100%;
    height: 42px;
    margin: 18px 0 0;
    padding: 15px 0 15px 32px;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    font-family: var(--font_family);
  
    font-size: 15px;
  
    font-weight: normal;
  
    font-stretch: normal;
  
    font-style: normal;
  
    line-height: normal;
  
    letter-spacing: 0.38px;
  
    text-align: left;
  
    color: var(--font_color) !important;
  }
  .fill_input::placeholder {
    font-family: var(--font_family);
  
    font-size: 15px;
  
    font-weight: normal;
  
    font-stretch: normal;
  
    font-style: normal;
  
    line-height: normal;
  
    letter-spacing: 0.38px;
  
    text-align: left;
  
    color: var(--font_color);
  }
  .fill_input:disabled {
    background: #f0eeee;
    color: var(--font_color);
  }

  .img_div{
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }

  .img_div img {
    width: 200px;
    height: 100px;
    object-fit: contain;
}

.img_div_new{
  display: flex;
  justify-content: center;
  margin: 10px 0;
  margin-bottom: 25px;
}

.img_div_new img{
  width: 130px;
  height: auto;
}

.business_logo {
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.business_logo img {
  width: 100%;
  height: 100%;
  /* height: 650px; */
  /* margin: auto; */
  /* border-radius: 0 30px 30px 0; */
}
.request_info{
  font-family: var(--font_family);
  color: #fff;
}
.request_detail{
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #fff;
  margin-bottom: 15px;
}
.request_title{
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  color: #fff;
  margin-bottom: 10px;
}
.request_info_point{
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #fff;
  margin-bottom: 10px;
}
.request_info_point li {
  list-style-type: none;
  margin-bottom: 2px;
  }
.request_info_point li:before {
  content: "";
  background: #fff;
  font-weight: bold;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  padding: 3px;
  margin: 0px 10px;
}

  .button_div{
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    margin: 0 40px;
  }
  .login_btn{
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    /* height: 41px; */
    border: 1px solid var(--secondary_color);
    background-color: var(--secondary_color);
    /* display: flex; */
    align-items: center;
    padding: 3px 8px;
    white-space: nowrap;
    margin-right: 18px;

  }

  .forgot_password {
    /* margin: 20px 0 0; */
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: center;
    color: var(--primary_color);
    cursor: pointer;
  }
  .forgot_pass{
    font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--primary_color);
  margin-bottom: 5px;
  }
  .inst_forgot{
    font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  }
  
  .contact_us{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--primary_color);
    cursor: pointer;
  }
  .country_drp{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .country_drp>div:first-child{
  width: 35%;
  margin-top: 20px;
  }
  .country_drp>div:last-child{
    width: 62%;
  }
  .country_drop{
    width: 100% !important;
    margin-right: 8px;
  }
.vt_country_select_container{
    display: flex;
    align-items: center;
    padding: 0 25px 0 10px;
    box-sizing: border-box;
    border: 1px solid #bdbdbd;
    border-radius: 0px;
    height: 51px;
  }
  .login_btn:last-child {
    margin-right: 0;
}

.modalb_wrap{
  margin-top: 15px;
}
.modb_icon{
  margin-bottom: 10px;
}
.modb_icon img{
  width: 92px;
  margin: auto;
}
.modb_title{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.35px;
  text-align: center;
  color: var(--font_color);
}

.email_error_msg {
  margin: 5px 0 0px;

  font-family: var(--font_family);

  font-size: 12px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.3px;

  color: red;
}

.email_error_msgotp{
  font-family: var(--font_family);

  font-size: 12px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.3px;

  text-align: center;

  color: red;
}

.email_id:focus {
  box-shadow: none;
  outline: none;
  border-color: #c3c3c3;
}

.email_id {
  width: 100%;
  height: 41px;
  margin: 30px 0 0;
  padding: 14px;
  border: solid 1px #c3c3c3;
  background-color: #fff;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--font_color);
}

.password_login_card{
  width: 550px;
  background-color: #fff;
  border-radius: 15px;
  /* margin-top: 10%; */
}
.password_login_card .login_form_container{
  width: unset;
}
.login_by_mail_id_text{
  font-family: var(--font_family);
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.55px;
  color: var(--font_color);
}

.password_update_text{
  font-family: var(--font_family);
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.55px;
  color: var(--primary_color);
  text-align: center;
}

.password_success_text{
  /* margin: 13px 73px 51px 74px; */
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.35px;
  color: #45aa01;
  text-align: center;
  margin: 20px 0;
}

.password_success_btn{
  width: 100%;
  margin: auto;
  /* margin-left: 152px; */
}

.btn_password {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  text-align: center;
  color: #fff;
  height: 50px;
  margin: 30px 0;
  padding: 13px;
  /* width: 100%; */
  background-color: var(--buttons_color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  margin: 30px auto;
  padding: 20px;
}

.pswd_text{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.35px;
  color: var(--font_color);
  margin-top: 6px;
}

.invalidmodal_wrap .brand_logo img{
  width: 190px;
  margin: 0 auto;
}
.inv_heading{
  font-family: var(--font_family);
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.55px;
  color: var(--primary_color);
  margin-bottom: 6px;
  text-align: center;
  margin: 10px 0;
  padding-top: 20px;
}
.inv_title{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.35px;
  color: var(--font_color);
  text-align: center;
  width: 90%;
  margin: auto;
  padding-bottom: 10px;
}
.button_wrap .reset_btn{
  height: 52px;
  padding: 10px 45px 10px;
  border-radius: 5px;
  background-color: var(--buttons_color);
  font-size: 20px;
  font-weight: normal;
  line-height: 1.1;
  letter-spacing: 0.5px;
  text-align: left;
  color: #fff;
}
.button_wrap .cancel_btn{
  height: 52px;
  padding: 10px 45px 10px;
  border-radius: 5px;
  border: solid 1px var(--secondary_color);
  background-color: #fff;
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: normal;
  line-height: 1.1;
  letter-spacing: 0.5px;
  color: var(--secondary_color);
}
.invalidmodal_wrap {
  width: 400px;
  margin: auto;
}

.button_wrap {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.brand_logo {
  margin-bottom: 15px;
}
.signup_card_container{
  width: 100%;
    height: 100%;
    /* margin-top: 60px !important; */
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.login_loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 38px;
  height: 38px;
  position: absolute;
  animation: spin 4s linear infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader_wrap_new{
  height: 50px;
  margin: 30px 0;
  padding: 13px;
  width: 150px;
  background-color: var(--secondary_color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  position: absolute;
  top: 10px;
}

.login_loader_up {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 38px;
  height: 38px;
  position: absolute;
  animation: spin 4s linear infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.emailtop{
  position: relative;
}
.emailtop img{
  position: absolute;
  top: 32px;
    left: 10px;
}
.passtop{
  position: relative;
}
.passtop img{
  position: absolute;
  top: 28px;
    left: 10px;
    cursor: pointer;
}
.forgot_email{
  position: relative;
}
.forgot_email img{
  position: absolute;
  /* top: 40px; */
  top: 32px;
    left: 10px;
}
.topdiv{
  display: flex;
    gap: 22px;
}
.country_div{
  display: flex;
  gap: 22px;
}
.fname_image{
  position: relative;
  width: 50%;
}
.fname_image img{
  position: absolute;
  top: 28px;
    left: 10px;
}
.lname_image{
  position: relative;
  width: 50%;
}
.lname_image img{
  position: absolute;
  top: 28px;
    left: 10px;
}
.emailt4{
  position: relative;
}
.emailt4 img{
  position: absolute;
  top: 32px;
    left: 10px;
}
.countrynames{
  position: relative;
  width: 50%;
}
.countryflag{
  position: absolute;
  top: 23px;
    left: 8px;
    width: 23px;
    /* z-index: 5; */
}
.contactnumber{
  position: relative;
  width: 50%;
}
.contactnumber img{
  position: absolute;
  top: 28px;
    left: 10px;
}
.t_company_name{
  position: relative;
}
.t_company_name img{
  position: absolute;
  top: 28px;
    left: 10px;
}
.t_address4{
  position: relative;
}
.t_address4 img{
  position: absolute;
  top: 23px;
    left: 7px;
    width: 27px;
}
.t_pincode{
  position: relative;
}
.t_pincode img{
  position: absolute;
  top: 30px;
    left: 5px;
    width: 30px;
}
.t_cdropdown{
  position: relative;
}
.t_cdropdown img{
  position: absolute;
  top: 11px;
    left: 5px;
    width: 27px;
}
.btn_wrap{
  display: flex;
  justify-content: center;
  position: relative;
}
.btn_wrap button{
  padding: 13px 25px;
  width: auto;
}

.sec_msg{
  font-size: 12px;
  color: var(--secondary_color);
  font-family: var(--font_family);
  margin-top: 40px;
  display: block;
  text-align: center;
}
.pass_res_per{
  position: relative;
  width: 100%;
}

.new_close{
  width: 24px;
  border-radius: 50%;
  border: 1px solid var(--primary_color);
  padding: 2px;
  color: var(--primary_color) !important;
  fill: var(--primary_color) !important;
  position: absolute;
  right: -45px;
  top: -30px;
  cursor: pointer;
}
.tick_mark{
  width: 40px;
  margin: 25px auto;
}
.country_nwFlex{
  display: flex;
  width: 100%;
  gap: 22px;
}
.country_select{
  width: 50%;
}
.organisation_select{
  width: 50%;
  position: relative;
}
.organisation_select input{
  width: 100%;
    height: 42px;
    margin: 12px 0 0;
    background-color: #fff;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--font_color) !important;
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #e0e0e0;
    padding: 0px 6px;
    cursor: pointer;
}
.organisation_drpdwn{
  background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    margin-top: 4px;
}
.orgtitle_element{
  padding: 5px 10px;
  cursor: pointer;
  font-size: 15px;
}
.orgtitle_element:hover{
  background-color: var(--primary_color);
  color: #fff;
}

.organisation_drpdwn::-webkit-scrollbar {
  width: 5px;
}
.organisation_drpdwn::-webkit-scrollbar-track {
  background: #e7e7e7; 
}
.organisation_drpdwn::-webkit-scrollbar-thumb {
  background: var(--primary_color); 
}
.organisation_drpdwn::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.organisation_inputCont{
  position: relative;
}

.organisation_inputCont .drop_iconnw {
  position: absolute;
  top: 22px;
  right: 10px;
  cursor: pointer;
}
.isd_main :global .IsdCodeDropdown_isd_search__NynyB {
  width: 100%;
  height: 42px;
  margin: 18px 0 0;
  padding: 15px 0 15px 32px;
  border: solid 1px #e0e0e0 !important;
  background-color: #fff;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--font_color) !important;
  margin-top: 0;
  border-radius: 0;
}

.isd_main :global .isd_input_cont {
  height: 42px;
  margin-top: 18px;
  width: 100%;
}

.isd_main :global .isd_drop_cont {
  width: 100%;
}

@media (min-width: 320px) and (max-width: 767px) {
  /* .password_login_card {
      width: 100%;
      border-radius: 10px;
  }
  
  .password_login_card .login_form_container {
      max-width: 100%;
      padding: 20px 20px;
  } */
  .button_wrap{
    gap: 10px;
    justify-content: center;
  }
  .invalidmodal_wrap {
    width: 100%;
    margin: auto;
  }
  .button_wrap .reset_btn,
  .button_wrap .cancel_btn{
    padding: 10px 20px;
  }
  
  }