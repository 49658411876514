img.starrating_img {
    width: 40%;
}

.centered{
    /* margin: 50px 0; */
    margin-top: 0;
    padding-top: 150px;
    height: 60vh;
    margin-bottom: 30px;
    height: calc(100vh - 140px - 90px);
  }
  .centered img{
    margin: auto;
  }
  @media (max-width: 767px){
    .centered {
        position: relative;
    }
  }


@media only screen and (max-width: 767px) and (min-width: 320px){
  .centered {
    /* top: 50%;
    left: 50%; */
    text-align: center;
    margin-top: 0;
    padding-top: 0px;
    height: auto;
    margin-bottom: 0px;
  }
  .centered img{
    width: auto !important;
    height: auto !important;
    text-align: center;
    margin: auto;
  }

    
}