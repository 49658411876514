/* img.starrating_img {
    width: 40%;
} */

/* .centered {
    position: fixed;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%); */
    /* background-color: #fff;
  } */
  .centered{
    margin: 50px 0;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px - 90px);
  }
  .centered img{
    margin: auto;
    mix-blend-mode: multiply;
  }
  @media (max-width: 767px){
    .centered {
        position: relative;
    }
  }


@media only screen and (max-width: 767px) and (min-width: 320px){
  .centered {
    /* top: 50%;
    left: 50%; */
    text-align: center;
  }
  .centered img{
    width: auto !important;
    height: auto !important;
    text-align: center;
    margin: auto;
  }

    
}